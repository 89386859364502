.preloader {
  animation: load 2s ease infinite;
}
@keyframes load {
  30% {
    transform: scale(1.2);
  }
  40%,
  60% {
    transform: scale(1.2);
  }
  50% {
    transform: scale(1.2);
  }
  70% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}
